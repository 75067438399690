import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
export default function Services() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        data-aos="flip-left"
        data-aos-duration="2000"
      >
        <div className="p-8 rounded shadow-xl sm:p-16">
          <div className="flex flex-col lg:flex-row">
            <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
              <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Our priority is&nbsp;
                <br className="hidden md:block" />
                customer{" "}
                <span className="inline-block text-yellow-400">
                  {" "}
                  satisfaction{" "}
                </span>
              </h2>
            </div>
            <div className="lg:w-1/2">
              <p className="mb-4 text-base text-gray-700">
                Our products are composed of different flavors, individually
                selected for their outstanding quality, taste and significant
                health benefits. Each one of our products are powerful
                nutrients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
